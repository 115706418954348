import "core-js/modules/es.function.name.js";
import { inRange } from '@/core/utils/numbers';
export var postApiError = function postApiError(app, $bugsnag, error, code, $config) {
  var _error$response$data$, _error$response, _error$response$data;
  var message = (_error$response$data$ = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.message) !== null && _error$response$data$ !== void 0 ? _error$response$data$ : 'Unknown error';
  $bugsnag.notify(new Error(message), function (event) {
    // set releaseStage
    event.app.releaseStage = $config.NODE_ENV;
    // set response error
    event.addMetadata('error', {
      code: code,
      response: Object.assign({}, error === null || error === void 0 ? void 0 : error.response)
    });
    // set user info
    if (app.$auth.user) {
      event.setUser(app.$auth.user.id, app.$auth.user.email, app.$auth.user.name);
    }
  });
};
export default (function (_ref, inject) {
  var _$config$MOCK_URL;
  var $axios = _ref.$axios,
    redirect = _ref.redirect,
    $toast = _ref.$toast,
    store = _ref.store,
    app = _ref.app,
    $bugsnag = _ref.$bugsnag,
    $config = _ref.$config;
  var isDev = $config.NODE_ENV === 'development';
  var applyAxiosInterceptors = function applyAxiosInterceptors(axios) {
    axios.interceptors.response.use(function (response) {
      store.dispatch('ui/setLoading', false);
      var message = response.data.message;
      if (message) {
        $toast.info(message);
      }
      return response.data;
    }, function (error) {
      var _response$data;
      var response = error.response;
      var code;
      try {
        code = parseInt(response.status);
      } catch (_unused) {}
      if (!isDev && !inRange(code, 400, 499)) {
        postApiError(app, $bugsnag, error, code, $config);
      }
      if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.message) {
        $toast.error(response.data.message);
      }
      if (code === 401) {
        redirect('/login');
      }
      throw error;
    });
    axios.interceptors.request.use(function (req) {
      var skipLoadingIndicator = req.skipLoadingIndicator;
      if (!skipLoadingIndicator) {
        store.dispatch('ui/setLoading', true);
      }
      return req;
    });
  };
  var axiosRaw = $axios.create();
  var mockAxios = $axios.create({
    baseURL: (_$config$MOCK_URL = $config.MOCK_URL) !== null && _$config$MOCK_URL !== void 0 ? _$config$MOCK_URL : $config.API_URL,
    withCredentials: false
  });
  applyAxiosInterceptors($axios);
  applyAxiosInterceptors(mockAxios);
  inject('axiosRaw', axiosRaw);
  inject('mockAxios', mockAxios);
});