export default (function (_ref, inject) {
  var app = _ref.app;
  app.store.dispatch('router/init', app.router);
  if (window) {
    app.store.dispatch('ui/setInnerWidth', window.innerWidth);
    window.addEventListener('resize', function () {
      app.store.dispatch('ui/setInnerWidth', window.innerWidth);
    });
  }
  inject('routerUtil', app.store.state.router);
});