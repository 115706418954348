import Vue from 'vue';
import Component from './component.vue';
import JwToast from './JwToast.js';
var Plugin = function Plugin(Vue) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var methods = JwToast(Vue, options);
  Vue.$jwToast = methods;
  Vue.prototype.$jwToast = methods;
};
Component.install = Plugin;
Vue.use(Component, {
  // duration: 5000,
  maxNumberToShow: 4
});
export default (function (_, inject) {
  inject('jwToast', Vue.prototype.$jwToast);
});