/* eslint-disable no-console */
export default (function (context, inject) {
  var $config = context.$config;
  var log = function log() {
    if ($config.NODE_ENV !== 'production') {
      var _console;
      console.log('** DEVELOPMENT LOG **');
      (_console = console).log.apply(_console, arguments);
    }
  };
  inject('log', log);
});