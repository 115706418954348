import "core-js/modules/web.timers.js";
import Vue from 'vue';
import gtmService from './gtm.service';
import gtmData from './gtm.data';
import gtmDirective from './gtm.directive';
export default (function (_ref, inject) {
  var app = _ref.app;
  Vue.directive('gtm', gtmDirective(app, gtmData));
  var gtm = gtmService(app, gtmData);
  setTimeout(function () {
    var _app$$auth;
    if ((_app$$auth = app.$auth) !== null && _app$$auth !== void 0 && _app$$auth.loggedIn) {
      var _app$$auth$user;
      gtm.dispatch('config', {
        user_id: (_app$$auth$user = app.$auth.user) === null || _app$$auth$user === void 0 ? void 0 : _app$$auth$user.id
      });
    }
  }, 300);
  inject('gtmEvent', gtm);
});