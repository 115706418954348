import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/web.timers.js";
export default (function (_ref, inject) {
  var $config = _ref.$config,
    store = _ref.store;
  var scriptTag = document.createElement('script');
  scriptTag.async = true;
  scriptTag.defer = true;
  scriptTag.type = 'text/javascript';
  scriptTag.id = 'jw-chatbot-script';
  scriptTag.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
  var blackList = {
    regexes: [/dashboard/]
  };
  var shouldIncludeChatbotDefaultMessage = function shouldIncludeChatbotDefaultMessage(routeName) {
    return !blackList.regexes.some(function (blackRegex) {
      return blackRegex.test(routeName);
    });
  };
  scriptTag.onload = function () {
    window.voiceflow.chat.load({
      verify: {
        projectID: $config.ALFRED_PROJECT_ID
      },
      url: 'https://general-runtime.voiceflow.com',
      versionID: $config.ALFRED_VERSION_ID
    }).then(function () {});
  };
  var displayChatbotDefaultMessage = false;
  inject('chatbot', {
    toggleChatbotDefaultMessage: function toggleChatbotDefaultMessage() {
      setTimeout(function () {
        var isMobile = store.getters['ui/device'].isMobile;
        if (shouldIncludeChatbotDefaultMessage(window.location.pathname) && !isMobile) {
          if (!displayChatbotDefaultMessage) {
            var _window, _window$voiceflow, _window$voiceflow$cha, _window$voiceflow$cha2;
            (_window = window) === null || _window === void 0 ? void 0 : (_window$voiceflow = _window.voiceflow) === null || _window$voiceflow === void 0 ? void 0 : (_window$voiceflow$cha = _window$voiceflow.chat) === null || _window$voiceflow$cha === void 0 ? void 0 : (_window$voiceflow$cha2 = _window$voiceflow$cha.proactive) === null || _window$voiceflow$cha2 === void 0 ? void 0 : _window$voiceflow$cha2.push({
              type: 'text',
              payload: {
                message: "Welcome to Jack Westin! I'm here to help if you need anything just click on the chat any time..."
              }
            });
          }
          displayChatbotDefaultMessage = true;
        } else {
          var _window2, _window2$voiceflow, _window2$voiceflow$ch, _window2$voiceflow$ch2;
          (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$voiceflow = _window2.voiceflow) === null || _window2$voiceflow === void 0 ? void 0 : (_window2$voiceflow$ch = _window2$voiceflow.chat) === null || _window2$voiceflow$ch === void 0 ? void 0 : (_window2$voiceflow$ch2 = _window2$voiceflow$ch.proactive) === null || _window2$voiceflow$ch2 === void 0 ? void 0 : _window2$voiceflow$ch2.clear();
          displayChatbotDefaultMessage = false;
        }
      }, 1000);
    },
    setup: function setup() {
      if (document.getElementById(scriptTag.id)) {
        var chatbotContainer = document.getElementById('voiceflow-chat');
        if (chatbotContainer) {
          chatbotContainer.style.display = 'block';
        }
      } else {
        document.body.appendChild(scriptTag);
      }
    },
    remove: function remove() {
      var chatbotContainer = document.getElementById('voiceflow-chat');
      if (chatbotContainer) {
        chatbotContainer.style.display = 'none';
      }
    }
  });
});